<script>
  import { Router, Link, Route } from "svelte-routing";
  import { onMount } from "svelte";

  import PdfEditor from "./routes/PdfEditor.svelte";
  import Home from "./routes/Home.svelte";
  import About from "./routes/About.svelte";

  let showProperties = true;

  const refreshPage = () => {
    setTimeout(() => {
      location.reload();
    }, 200);
  };

  const clickProperties = () => {
    if (location.pathname === "/about") {
      window.location.href = "/";
    } 
  };

  window.addEventListener("popstate", (event) => {
    setTimeout(() => {
      location.reload();
    }, 200);
  });

  const downloadPdf = () => {
    window.open(location.origin + "/HouseRules.pdf", "_blank");
  };

  const onClickResponsiveMenuButton = () => {
    var x = document.getElementById("centered_nav");
    if (x.className === "rc_nav") {
      x.className += " responsive";
    } else {
      x.className = "rc_nav";
    }
  };

  onMount(() => {
    showProperties = location.pathname !== "/about";

    return () => {
      showProperties = null;
    };
  });
</script>

<Router>
  <div class="rc_nav" id="centered_nav">
    <Link to="/" on:click={refreshPage}>Home</Link>
    <Link to="/about" on:click={refreshPage}>About</Link>
    <Link on:click={downloadPdf} to="/">Rules</Link>
    {#if showProperties}
      <a on:click={clickProperties} title="Properties" href="/#properties">Properties</a>
    {/if}
    <a
      href="javascript:"
      title="Menu"
      style="font-size:18px;"
      class="icon"
      on:click={onClickResponsiveMenuButton}
    >
      &#9776;
    </a>
  </div>

  <Route path="about">
    <About />
  </Route>
  <Route path="/">
    <Home />
  </Route>
  <Route path="pdfEditor">
    <PdfEditor />
  </Route>
</Router>
