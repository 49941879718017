<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let x = 0;
  export let y = 0;
  export let width = 50;
  export let height = 50;
  const dispatch = createEventDispatcher();
</script>

<div
  on:click={() => {
    dispatch("update");
  }}
  class="absolute left-0 top-0 select-none cursor-pointer"
  style="width: {width}px; height: {height}px; transform:
  translate({x}px, {y}px);"
>
  <div
    class="absolute w-full h-full border border-dashed
    border-black"
  />
  <p class="text-xs">SIGN HERE</p>
</div>
