<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import Tailwind from "../Tailwind.svelte";
  import PDFPage from "../PDFPage.svelte";
  import Image from "../Image.svelte";
  import Text from "../Text.svelte";
  import Drawing from "../Drawing.svelte";
  import DrawingCanvas from "../DrawingCanvas.svelte";
  import SignatureBox from "../SignatureBox.svelte";
  import prepareAssets, { fetchFont } from "../utils/prepareAssets.js";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "../utils/asyncReader.js";
  import { ggID } from "../utils/helper.js";
  import { save, sendEmail } from "../utils/PDF.js";
  import { tick } from 'svelte';
  import { writable } from 'svelte/store';
  const genID = ggID();
  let pdfFile;
  let pdfName = "";
  let pages = [];
  let pagesScale = [];
  let allObjects = [];
  let currentFont = "Times-Roman";
  let focusId = null;
  let selectedPageIndex = -1;
  let saving = false;
  let addingDrawing = false;
  let addingSignatureIndexBox = null;
  let sendMessage = "";
  // for test purpose


const countdown = writable(5);
// Function to start the countdown
function startCountdown() {
  let counter = 5;
  countdown.set(counter);
  // Decrement the countdown every second
  const interval = setInterval(() => {
    counter--;
    countdown.set(counter);
    // Stop the countdown when it reaches 0
    if (counter === 0) {
      clearInterval(interval);
    }
  }, 1000);
}

  onMount(async () => {
  try {
      const res = await fetch("/test.pdf");
      const pdfBlob = await res.blob();
      await addPDF(pdfBlob);
      selectedPageIndex = 0;
      setTimeout(() => {
        fetchFont(currentFont);
        prepareAssets();
      }, 5000);
      // const imgBlob = await (await fetch("/test.jpg")).blob();
      // addImage(imgBlob);
      // addTextField("測試!");
      // addDrawing(200, 100, "M30,30 L100,50 L50,70", 0.5);
    } catch (e) {
      console.log(e);
    }
  });
  async function onUploadPDF(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf") return;
    selectedPageIndex = -1;
    try {
      await addPDF(file);
      selectedPageIndex = 0;
    } catch (e) {
      console.log(e);
    }
  }
  async function addPDF(file) {
    try {
      const pdf = await readAsPDF(file);
      pdfName = file.name;
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      allObjects = pages.map(() => []);
      pagesScale = Array(numPages).fill(1);
    } catch (e) {
      console.log("Failed to add pdf.");
      throw e;
    }
  }
  async function onUploadImage(e) {
    const file = e.target.files[0];
    if (file && selectedPageIndex >= 0) {
      addImage(file);
    }
    e.target.value = null;
  }
  async function addImage(file) {
    try {
      // get dataURL to prevent canvas from tainted
      const url = await readAsDataURL(file);
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;
      const object = {
        id,
        type: "image",
        width,
        height,
        x: 0,
        y: 0,
        payload: img,
        file,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onAddTextField() {
    if (selectedPageIndex >= 0) {
      addTextField();
    }
  }

  let isFormSubmitted = false;

  async function onSubmitClickButton() {
    const message = await sendEmail(pdfFile, allObjects, pdfName, pagesScale);
    sendMessage = message;
    isFormSubmitted = true;
    startCountdown();
  }

  $: if (isFormSubmitted) {
    setTimeout(() => {
      savePDF();
    }, 100);
    setTimeout(() => {
      window.location.href = "/";
    }, 6100);
  }

  function addTextField(text = "New Text Field") {
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1.4,
      fontFamily: currentFont,
      x: 0,
      y: 0,
      limit: 26,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }

  function onAddDrawing() {
    if (selectedPageIndex >= 0) {
      addingDrawing = true;
    }
  }
  function addDrawing(originWidth, originHeight, path, scale = 1) {
    const id = genID();
    const object = {
      id,
      path,
      type: "drawing",
      x: 0,
      y: 0,
      originWidth,
      originHeight,
      width: originWidth * scale,
      scale,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function selectFontFamily(event) {
    const name = event.detail.name;
    fetchFont(name);
    currentFont = name;
  }
  function selectPage(index) {
    selectedPageIndex = index;
  }

  function addDrawingAfterClickOnSignatureBox(
    x,
    y,
    originWidth,
    originHeight,
    path,
    scale = 1
  ) {
    const id = genID();
    const object = {
      id,
      path,
      type: "drawing",
      x: x,
      y: y,
      originWidth,
      originHeight,
      width: originWidth * scale,
      scale,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }

  function updateObject(objectId, payload) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.map((object) =>
            object.id === objectId ? { ...object, ...payload } : object
          )
        : objects
    );
  }
  function deleteObject(objectId) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.filter((object) => object.id !== objectId)
        : objects
    );
  }
  function onMeasure(scale, i) {
    pagesScale[i] = scale;
  }
  // FIXME: Should wait all objects finish their async work
  async function savePDF() {
    if (!pdfFile || saving || !pages.length) return;
    saving = true;
    try {
      await save(pdfFile, allObjects, pdfName, pagesScale);
    } catch (e) {
      console.log(e);
    } finally {
      saving = false;
    }
  }

  function startUp() {
    function addSignatureBox(x, y, width = 50, height = 50) {
      const id = genID();
      const object = {
        id,
        x: x,
        y: y,
        width: width,
        height: height,
        type: "signatureBox",
        availability: true,
      };

      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    }

    function preloadSignatureBoxes() {
      selectPage(1);
      // addSignatureBox(555, 490);
      // addSignatureBox(555, 605);
      // addSignatureBox(555, 680);

      selectPage(2);
      // addSignatureBox(555, 180);
      // addSignatureBox(555, 295);
      // addSignatureBox(555, 430);
      addSignatureBox(235, 540, 90, 30);
      addSignatureBox(235, 580, 90, 30);
      addSignatureBox(235, 615, 90, 30);
    }

    function preloadTextFields() {
      function addImaginaryCheckmarkField(x, y) {
        const id = genID();
        fetchFont(currentFont);
        const object = {
          id,
          text: "&nbsp&nbsp&nbsp",
          type: "text",
          size: 16,
          width: 0, // recalculate after editing
          lineHeight: 1.4,
          fontFamily: currentFont,
          lines: [],
          x: x,
          y: y,
          limit: 12,
        };
        allObjects = allObjects.map((objects, pIndex) =>
          pIndex === selectedPageIndex ? [...objects, object] : objects
        );
      }

      function addTextFieldInPosition(x, y) {
        const id = genID();
        fetchFont(currentFont);
        const object = {
          id,
          text: "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp",
          type: "text",
          size: 16,
          width: 0, // recalculate after editing
          lineHeight: 1.4,
          fontFamily: currentFont,
          lines: [],
          x: x,
          y: y,
          limit: 26,
        };
        allObjects = allObjects.map((objects, pIndex) =>
          pIndex === selectedPageIndex ? [...objects, object] : objects
        );
      }

      selectPage(0);
      addTextFieldInPosition(150, 128);
      addTextFieldInPosition(70, 158);
      addTextFieldInPosition(100, 190);
      addTextFieldInPosition(250, 190);
      addImaginaryCheckmarkField(450, 190);
      addTextFieldInPosition(110, 228);

      addImaginaryCheckmarkField(215, 228);
      addImaginaryCheckmarkField(260, 228);

      addImaginaryCheckmarkField(420, 228);

      addImaginaryCheckmarkField(130, 255);
      addImaginaryCheckmarkField(185, 255);
      addImaginaryCheckmarkField(250, 255);
      addImaginaryCheckmarkField(320, 255);

      addTextFieldInPosition(140, 286, 20, 300);
      addTextFieldInPosition(110, 314);

      addTextFieldInPosition(240, 314);
      addTextFieldInPosition(430, 314);

      addImaginaryCheckmarkField(175, 360);
      addImaginaryCheckmarkField(280, 360);

      addImaginaryCheckmarkField(50, 400);
      addImaginaryCheckmarkField(90, 400);

      addTextFieldInPosition(30, 422);
      addTextFieldInPosition(180, 422);
      addTextFieldInPosition(350, 422);
      addTextFieldInPosition(30, 460);
      addTextFieldInPosition(180, 460);
      addTextFieldInPosition(350, 460);

      addImaginaryCheckmarkField(360, 515);
      addImaginaryCheckmarkField(400, 515);

      addTextFieldInPosition(30, 538);
      addTextFieldInPosition(180, 538);
      addTextFieldInPosition(350, 538);

      addImaginaryCheckmarkField(180, 588);
      addImaginaryCheckmarkField(215, 588);

      addTextFieldInPosition(350, 588);

      addImaginaryCheckmarkField(210, 620);
      addImaginaryCheckmarkField(310, 620);
      addImaginaryCheckmarkField(390, 620);
      // add checkmark field

      addTextFieldInPosition(50, 640);

      // addTextFieldInPosition(70, 638, 20, 50);
      addTextFieldInPosition(90, 668);
      addTextFieldInPosition(260, 668);
      addTextFieldInPosition(380, 668);
      addTextFieldInPosition(30, 696);
      addTextFieldInPosition(180, 696);
      addTextFieldInPosition(350, 696);

      selectPage(1);
      addImaginaryCheckmarkField(270, 120);
      addImaginaryCheckmarkField(310, 120);

      addTextFieldInPosition(125, 140);
      addTextFieldInPosition(125, 160);
      addTextFieldInPosition(125, 180);
      addTextFieldInPosition(355, 140);
      addTextFieldInPosition(355, 160);
      addTextFieldInPosition(355, 180);

      addImaginaryCheckmarkField(380, 220);
      addImaginaryCheckmarkField(420, 220);

      addTextFieldInPosition(75, 265);
      addTextFieldInPosition(275, 265);
      addTextFieldInPosition(407, 265);

      addTextFieldInPosition(75, 305);
      addTextFieldInPosition(275, 305);
      addTextFieldInPosition(407, 305);

      addTextFieldInPosition(75, 375);
      addTextFieldInPosition(275, 375);
      addTextFieldInPosition(407, 375);

      addTextFieldInPosition(75, 415);
      addTextFieldInPosition(275, 415);
      addTextFieldInPosition(407, 415);

      //Initials page 1
      addImaginaryCheckmarkField(567, 511);
      addImaginaryCheckmarkField(567, 624);
      addImaginaryCheckmarkField(567, 700);

      selectPage(2);

      addImaginaryCheckmarkField(85, 495);
      addImaginaryCheckmarkField(337, 495);
      addImaginaryCheckmarkField(85, 515);
      addImaginaryCheckmarkField(337, 515);

      addTextFieldInPosition(45, 555);
      addTextFieldInPosition(405, 550);

      addTextFieldInPosition(45, 590);
      addTextFieldInPosition(405, 590);

      addTextFieldInPosition(45, 625);
      addTextFieldInPosition(405, 625);

      //initials page 2
      addImaginaryCheckmarkField(567, 195);
      addImaginaryCheckmarkField(567, 311);
      addImaginaryCheckmarkField(567, 447);
    }

    setTimeout(() => {
      preloadTextFields();
      preloadSignatureBoxes();
    }, 200);
    pdfName = "Harwood_application.pdf";
  }
</script>

<svelte:window
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF}
/>
<Tailwind />
<main
  class="flex flex-col items-center py-16 bg-gray-100 min-h-screen scale-300"
>
  <div
    class="fixed z-10 bottom-0 left-0 right-0 h-12 flex justify-center items-center
      bg-gray-200 border-b border-gray-300"
  >
    <input
      type="file"
      name="pdf"
      id="pdf"
      on:change={onUploadPDF}
      class="hidden"
    />
    <input
      type="file"
      id="image"
      name="image"
      class="hidden"
      on:change={onUploadImage}
    />
    <!-- <label
      class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
        font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
      for="pdf"
    >
      Choose PDF
    </label> -->
    <div
      class="relative mr-3 flex h-8 bg-gray-400 rounded-sm overflow-hidden
        md:mr-4 hidden"
    >
      <label
        class="flex items-center justify-center h-full w-8 hover:bg-gray-500
          cursor-pointer"
        for="image"
        class:cursor-not-allowed={selectedPageIndex < 0}
        class:bg-gray-500={selectedPageIndex < 0}
      >
        <img src="image.svg" alt="An icon for adding images" />
      </label>
      <label
        class="flex items-center justify-center h-full w-8 hover:bg-gray-500
          cursor-pointer"
        for="text"
        class:cursor-not-allowed={selectedPageIndex < 0}
        class:bg-gray-500={selectedPageIndex < 0}
        on:click={onAddTextField}
      >
        <img src="notes.svg" alt="An icon for adding text" />
      </label>
      <label
        class="flex items-center justify-center h-full w-8 hover:bg-gray-500
          cursor-pointer"
        on:click={onAddDrawing}
        class:cursor-not-allowed={selectedPageIndex < 0}
        class:bg-gray-500={selectedPageIndex < 0}
      >
        <img src="gesture.svg" alt="An icon for adding drawing" />
      </label>
    </div>
    <div class="justify-center mr-3 md:mr-4 w-full max-w-xs hidden md:flex">
      <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name" />
      <input
        placeholder="Rename your PDF here"
        type="text"
        class="flex-grow bg-transparent"
        bind:value={pdfName}
      />
    </div>
    <button
      on:click={onSubmitClickButton}
      class="w-40 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
      md:px-4 mr-3 md:mr-4 rounded"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      Submit
    </button>

    <button
      on:click={savePDF}
      class="w-40 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
        md:px-4 mr-3 md:mr-4 rounded"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      {saving ? "Saving" : "Download"}
    </button>

    {#if sendMessage.length}
      <h5 style="color: green;">
        {sendMessage}
      </h5>
    {/if}
  </div>
  {#if addingDrawing}
    <div
      transition:fly={{ y: -200, duration: 500 }}
      class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
        shadow-lg"
      style="height: 50%;"
    >
      <DrawingCanvas
        on:finish={(e) => {
          const { originWidth, originHeight, path } = e.detail;
          let scale = 1;
          console.log(addingSignatureIndexBox);
          if (originWidth > 500) {
            scale = 500 / originWidth;
          }
          if (addingSignatureIndexBox == 77) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              500,
              500,
              originWidth,
              originHeight,
              path,
              0.2
            );
            console.log(allObjects[1]);

            // allObjects[1];
          } else if (addingSignatureIndexBox == 78) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              550,
              600,
              originWidth,
              originHeight,
              path,
              0.2
            );
          } else if (addingSignatureIndexBox == 79) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              550,
              650,
              originWidth,
              originHeight,
              path,
              0.2
            );
          } else if (addingSignatureIndexBox == 80) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              550,
              180,
              originWidth,
              originHeight,
              path,
              0.2
            );
          } else if (addingSignatureIndexBox == 81) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              550,
              300,
              originWidth,
              originHeight,
              path,
              0.2
            );
          } else if (addingSignatureIndexBox == 82) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              550,
              430,
              originWidth,
              originHeight,
              path,
              0.2
            );
          } else if (addingSignatureIndexBox == 83) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              235,
              540,
              originWidth,
              originHeight,
              path,
              0.1
            );
          } else if (addingSignatureIndexBox == 84) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              235,
              580,
              originWidth,
              originHeight,
              path,
              0.1
            );
          } else if (addingSignatureIndexBox == 85) {
            addingSignatureIndexBox = null;
            addDrawingAfterClickOnSignatureBox(
              235,
              615,
              originWidth,
              originHeight,
              path,
              0.1
            );
          }

          // addDrawing(originWidth, originHeight, path, scale);
          addingDrawing = false;
        }}
        on:cancel={() => (addingDrawing = false)}
      />
    </div>
  {/if}
  {#if pages.length}
    <!-- svelte-ignore empty-block -->
    {#if startUp()}{/if}
    <div class="flex justify-center px-5 w-full md:hidden">
      <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name" />
      <input
        placeholder="Rename your PDF here"
        type="text"
        class="flex-grow bg-transparent"
        bind:value={pdfName}
      />
    </div>
    <div class="w-full">
      {#each pages as page, pIndex (page)}
        <div
          class="p-5 w-full flex flex-col items-center overflow-hidden"
          on:mousedown={() => selectPage(pIndex)}
          on:touchstart={() => selectPage(pIndex)}
        >
          <div
            class="relative shadow-lg"
            class:shadow-outline={pIndex === selectedPageIndex}
          >
            <PDFPage
              on:measure={(e) => onMeasure(e.detail.scale, pIndex)}
              {page}
            />
            <div
              class="absolute top-0 left-0 transform origin-top-left"
              style="transform: scale({pagesScale[
                pIndex
              ]}); touch-action: none;"
            >
              {#each allObjects[pIndex] as object, index}
                <!-- it should be {#each allObjects[pIndex] as object (object.id)} -->
                {#if object.type === "image"}
                  <Image
                    on:update={(e) => updateObject(object.id, e.detail)}
                    on:delete={() => deleteObject(object.id)}
                    file={object.file}
                    payload={object.payload}
                    x={object.x}
                    y={object.y}
                    width={object.width}
                    height={object.height}
                    pageScale={pagesScale[pIndex]}
                  />
                {:else if object.type === "text"}
                  <Text
                    on:update={(e) => updateObject(object.id, e.detail)}
                    on:delete={() => deleteObject(object.id)}
                    on:selectFont={selectFontFamily}
                    text={object.text}
                    x={object.x}
                    y={object.y}
                    size={object.size}
                    lineHeight={object.lineHeight}
                    fontFamily={object.fontFamily}
                    limit={object.limit}
                  />
                {:else if object.type === "signatureBox" && object.availability == true}
                  <SignatureBox
                    x={object.x}
                    y={object.y}
                    width={object.width}
                    height={object.height}
                    on:update={() => {
                      // console.log(object.id);
                      addingSignatureIndexBox = object.id;
                      addingDrawing = true;
                    }}
                  />
                {:else if object.type === "drawing"}
                  <Drawing
                    on:update={(e) => updateObject(object.id, e.detail)}
                    on:delete={() => deleteObject(object.id)}
                    path={object.path}
                    x={object.x}
                    y={object.y}
                    width={object.width}
                    originWidth={object.originWidth}
                    originHeight={object.originHeight}
                    pageScale={pagesScale[pIndex]}
                  />
                {/if}
              {/each}
              <!-- <SignatureBox x={50} y={100} /> -->
            </div>
          </div>
        </div>
      {/each}
    </div>
  {:else}
    <div class="w-full flex-grow flex justify-center items-center">
      <span class=" font-bold text-3xl text-gray-500">Drag something here</span>
    </div>
  {/if}
  {#if isFormSubmitted}
  <div class="fixed z-20 top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-green-800">
    <div class="text-white text-10xl font-bold text-center animate-fade-in">
      <!-- Large version for desktop -->
      <div class="text-white text-6xl hidden md:block">&#10003;</div>
      <div class="text-6xl hidden md:block">
        Thank you for applying to HarwoodHouses
      </div>
      <div class="text-3xl hidden md:block">
        A copy of this application has been saved to your downloads folder
      </div>
      <div class="text-white text-3xl hidden font-bold md:block">
        <p class="text-white text-3xl hidden font-bold md:block">You are being redirected to the home page in {#if $countdown > -1}{$countdown}{/if}</p>
      </div>

      <!-- Smaller version for mobile -->
      <div class="text-white text-4xl md:hidden">&#10003;</div>
      <div class="text-4xl md:hidden">
        Thank you for applying to HarwoodHouses
      </div>
      <div class="text-xl md:hidden">A copy of this application has been saved to your downloads folder</div>
      <div class="text-white text-xl pt-4  md:hidden">
        <p class="text-white text-xl md:hidden">You are being redirected to the home page in {#if $countdown > -1}{$countdown}{/if}</p>
      </div>
    </div>
  </div>

  <style>
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .animate-fade-in {
      animation: fade-in 1s ease-out;
      opacity: 1;
    }
  </style>
{/if}

</main>
